import cookieCutter from 'cookie-cutter';
import { needsByVertical } from '../consts/verticalContents';
import verticalList from './verticalList';

const HS_FORM_API = 'https://api.hsforms.com/submissions/v3/integration/submit';
const HS_SIGNUP_FORM_ID = 'a7b71d79-6390-4dc2-b243-286c221ee359';
const HS_PORTAL_ID = '5606725';
const HS_ONBOARDING_FORM_ID = '7998077f-46b9-475a-ad0d-acc1d37c5714';
async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow', // manual, *follow, error
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

const sendOnboardingForm = async (data, pageUri, pageName, url) => {
  const {
    email, company, jobTitle, companySize, vertical, needs, hsCookie,
  } = data;

  return postData(url, {
    context: {
      pageUri,
      pageName,
      hutk: hsCookie,
    },
    fields: [
      { name: 'company', value: company },
      { name: 'jobtitle', value: jobTitle },
      { name: 'email', value: email },
      { name: 'company_size', value: companySize },
      { name: 'vertical', value: vertical },
      { name: 'needs', value: needs },
    ],
  });
};

const sendSignupForm = async (data, pageUri, pageName, url) => {
  const {
    firstName, lastName, email, plan, hsCookie, apstercreatedate,
  } = data;

  return postData(url, {
    context: {
      pageUri,
      pageName,
      hutk: hsCookie,
    },
    fields: [
      { name: 'firstname', value: firstName },
      { name: 'lastname', value: lastName },
      { name: 'email', value: email },
      { name: 'plan', value: plan },
      { name: 'apstercreatedate', value: `${apstercreatedate}` },
    ],
  });
};

export const sendForm = async (formType = 'signup', data, plan = 'trial') => {
  const apstercreatedate = Date.now();
  const hsCookie = cookieCutter.get('hubspotutk');
  // Example POST method implementation:
  switch (formType) {
    case 'onboarding': {
      const url = `${HS_FORM_API}/${HS_PORTAL_ID}/${HS_ONBOARDING_FORM_ID}`;
      const {
        vertical, companySize, company, needs, jobTitle, emails,
      } = data;

      const [email] = emails;
      const verticalFromList = verticalList.includes(vertical) ? vertical : 'Other';
      const needsFromList = needs.map((need) => (needsByVertical(vertical).includes(need) ? need : 'Other'));
      return sendOnboardingForm({
        companySize,
        company,
        jobTitle,
        email,
        vertical: verticalFromList,
        needs: needsFromList.join(';'),
        hsCookie,
        apstercreatedate,
      }, 'portal.apester.com/onboarding', 'Apester Onboarding', url);
    }
    case 'signup':
    default: {
      const {
        firstName, lastName, emails,
      } = data;
      const [email] = emails;

      const url = `${HS_FORM_API}/${HS_PORTAL_ID}/${HS_SIGNUP_FORM_ID}`;
      return sendSignupForm({
        firstName, lastName, email, hsCookie, apstercreatedate, plan,
      }, 'portal.apester.com/signup-success', 'Apester signup', url);
    }
  }
};

export default sendForm;
