const verticalList = [
  'Brand',
  'E-commerce',
  'Tech',
  'Business',
  'Education',
  'Travel',
  'Agency',
  'Media Groups',
  'Entertainment',
  'Sport',
  'News',
  'Health',
  'Banking',
  'Insurance',
  'Retail',
  'Shopping',
  'Gaming',
  'Lifestyle',
  'Beauty',
  'Food',
  'Automotive',
  'Gambling',
  'Other',
];
export default verticalList;
