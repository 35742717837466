/* eslint-disable import/prefer-default-export */

export enum Device {
  MOBILE = 'mobile',
  DESKTOP = 'desktop',
}

export const ENGINES = {
  POLL: 'poll',
  COUNTDOWN: 'countdown',
  VIDEOPOLL: 'videoPoll',
  AUTOPOLL: 'autoPoll',
  STORY: 'story',
  GALLERY: 'gallery',
  CUSTOM: 'custom',
  QUIZ: 'quiz',
  AUTOQUIZ: 'autoQuiz',
  PERSONALITY: 'personality',
  VIDEOQUIZ: 'videoQuiz',
  VIDEOPERSONALITY: 'videoPersonality'
}
