/* eslint-disable @typescript-eslint/naming-convention */
import pipe from 'ramda/src/pipe';
import toLower from 'ramda/src/toLower';
import pathOr from 'ramda/src/pathOr';
import equals from 'ramda/src/equals';
import { ENGINES } from './general';

// TODO: in the future we'll build managing page, to create different verticals.

export enum Vertical {
  ALL = 'all',
  SPORT = 'sport',
  SPORTS = 'sports',
  GAMING = 'gaming',
  FINANCE = 'finance',
  TECH = 'tech',
  TECHNOLOGY = 'technology',
  E_COMMERCE = 'e-commerce',
  COMMERCE = 'commerce',
  NEWS = 'news',
  ENTERTAINMENT = 'entertainment',
}

export const moreEnginesFilters = [
  { title: 'Poll', name: ENGINES.POLL },
  { title: 'Countdown', name: ENGINES.COUNTDOWN },
  { title: 'Video Poll', name: ENGINES.VIDEOPOLL },
  { title: 'Story', name: ENGINES.STORY },
  { title: 'Gallery', name: ENGINES.GALLERY },
  { title: 'Custom', name: ENGINES.CUSTOM },
  { title: 'Quiz', name: ENGINES.QUIZ },
  { title: 'Personality', name: ENGINES.PERSONALITY },
  { title: 'Video Quiz', name: ENGINES.VIDEOQUIZ },
  { title: 'Video Personality', name: ENGINES.VIDEOPERSONALITY },
]

export const moreFilters = [
  'Poll',
  'Countdown',
  'Video Poll',
  'Story',
  'Gallery',
  'Custom',
  'Quiz',
  'Personality',
  'Video Quiz',
  'Video Personality',
] 

export const all = {
  name: 'ALL',
  vertical: Vertical.ALL,
  filters: {
    needs: [
      'Engagement',
      'Collect Data',
      'Monetization',
      'Merchandise',
      'Segmentation',
      'Journey Personalization',
      'Subscription',
      'Advocacy',
    ],
    moreFilters: [...moreFilters],
  },
  headline: 'Variety of templates to increase user engagement and sales',
  text: `Meet your audience across all screens with interactive templates for your website and social 
    media. Increase their engagement while gathering the insights you need to take your sales and
    marketing to the next level.`,
  backgroundColor: '#EB323C',
  headerTxtColor: '#ffffff',
  imageName: 'general',
};

export const sport_gaming = {
  name: 'SPORT & GAMING',
  vertical: `${Vertical.SPORT}-${Vertical.GAMING}`,
  filters: {
    needs: [
      'Engagement',
      'Subscription',
      'Monetization',
      'Merchandise',
      'Tickets Sales',
      'Sponsorship',
    ],
    timeline: [
      'Always On',
      'Pre Game',
      'Mid Game',
      'Post Game',
      'Off Season',
    ],
    moreFilters: [...moreFilters],
  },
  headline: 'Winning Interactive templates, tailored' +
      ' exactly to sprint your fans',
  text: `Here’s your chance to provide sports and gaming fans that extra km, using engaging and creative
    content. Their passion can now turn to further subscriptions, more tickets and higher
    merchandise sales. Gather better information about visitors to improve marketing activities and
    provide a great and fun customer experience.`,
  backgroundColor: '#FFC33C',
  headerTxtColor: '#000000',
  imageName: 'sport',
};

export const technology_commerce = {
  name: 'TECH & COMMERCE',
  vertical: `${Vertical.TECH}-${Vertical.COMMERCE}`,
  filters: {
    needs: [
      'Engagement',
      'Subscription',
      'Monetization',
      'Merchandise',
      'Tickets Sales',
      'Sponsorship',
    ],
    moreFilters: [...moreFilters],
  },
  headline: 'Interactive Templates ready for your' +
      ' tech & shopping visitors',
  text: `Give your best-deal seeking visitors especially tailored technology & commerce content, that is
    interactive, and meets them across all online media. Leverage their passion into more
    subscriptions and gather better insights to revamp existing marketing activities, while providing
    best customer experience.`,
  backgroundColor: '#6432FF',
  headerTxtColor: '#ffffff',
  imageName: 'tech',
};

export const news_entertainment = {
  name: 'NEWS & ENTERTAINMENT',
  vertical: `${Vertical.NEWS}-${Vertical.ENTERTAINMENT}`,
  filters: {
    needs: [
      'Engagement',
      'Subscription',
      'Monetization',
      'Merchandise',
      'Tickets Sales',
      'Sponsorship',
    ],
    moreFilters: [...moreFilters],
  },
  headline: 'Luring Interactive Templates' +
      ' especially tailored to your followers',
  text: `Your headline-seeking fans are always on the lookout for that extra scoop. Provide
    them with news & entertainment content to turn their passion to more subscriptions
    and overall sales. With Apester you can learn more about your visitor’s needs, gather
    insights and use them to improve existing marketing activities.`,
  backgroundColor: '#C822A3',
  headerTxtColor: '#ffffff',
  imageName: 'entertainment',
};

export const finance = {
  name: 'FINANCE',
  vertical: Vertical.FINANCE,
  filters: {
    needs: [
      'Engagement',
      'Collect Data',
      'Monetization',
      'Merchandise',
      'Segmentation',
      'Journey Personalization',
      'Subscription',
      'Advocacy',
    ],
    moreFilters: [...moreFilters],
  },
  headline: 'Luring Interactive Templates especially tailored to your followers',
  text: `Your stats-loving, stock-market, money-oriented fans are looking for that extra dime experience.
    So why not upscale their encounters and interact with them on a whole new level? Access more
    insights about visitors, and scale-up existing marketing activities, to provide the best customer
    experience.`,
  backgroundColor: '#2E87EF',
  headerTxtColor: '#ffffff',
  imageName: 'finance',
};

export const isSportVertical = pipe(toLower, equals('sport'));

export const needsByVertical = (vertical: string) => pathOr([], ['filters', 'needs'])(
  isSportVertical(vertical) ? sport_gaming : all,
);

// IMPORTANT: Once we add more verticals here, we should also update
// vertical options in Editor (getUserVertical function in utils.js)
export default {
  sport_gaming,
  technology_commerce,
  news_entertainment,
  finance,
  all,
  moreEnginesFilters,
};
